import $api from "../http/AuthAPI";

export default class AuthService {
	static async registration(email, password1, password2) {
		return await $api.post("/registration/", { email, password1, password2 });
	}
	static async login(email, password) {
		return $api.post("/login/", { email, password });
	}
	static async logout() {
		return $api.post("/logout/");
	}
	static async activationEmail(activationLink, key) {
		return await $api.post(`https://4dev.itcoty.ru${activationLink}`, {key});
	}
	static async checkAuth() {
		return await $api.get(`/token/refresh/`)
	}
}
