export const routes = {
	home: '/',
	registration: "/registration",
	authorization: "/authorization",
	forgotPassword: "/forgotPassword",
	documents: "/docs",
	jobs: "/jobs",
	jobsVacancies: "/jobs/vacancies",
	jobsInternships: "/jobs/internships",
	telegram: "/telegram",
	detailJobs: "/jobs/:idVacancy",
	it: "/it",
	courses: "/it/courses",
	detailCurses: "/it/courses/:id",
	event: "/it/event",
	internship: "/it/internship",
	job: "/it/job",
	enterITArticles: "/it/articles",
	internships: "/internships",
	events: "/events",
	mentors: "/mentors",
	enterities: "/enterit",
	more: "/more",
	news: "/news",
	termsOfUse: "/docs/termsOfUse",
	publicOffer: "/docs/publicOffer",
	privacyPolicy: "/docs/privacyPolicy",
	forBusiness: '/forBusiness',
	forStartups: '/forStartups',

	//temporary
	temporary: "/temporary",
	placeholder: "/placeholder",
	activateEmail: '/api/v1/verify_email/:key'
};
