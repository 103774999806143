import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {checkAuth} from "./store/reducers/CheckAuthReducer"
import Main from "./common/Layout/Main/Main";
import {selectUserData} from "./store/reducers/UserReducer";

function App() {
    const dispatch = useDispatch();
    const user = useSelector(selectUserData)
    console.log(user)
    useEffect(() => {
        if (localStorage.getItem('token')) {
            dispatch(checkAuth())
        }

    }, [])

    return (
        // <RoutesComponent/>
        <Main/>
        // <div>
        //     <Sidebar/>
        //     <Header/>
        //     <Routes>
        //         <Route exact path={routes.home} element={<home/>}/>
        //         <Route path={routes.registration} element={<Registration/>}/>
        //         <Route path={routes.authorization} element={<Authorization/>}/>
        //         <Route path={routes.jobs} element={<Jobs/>}/>
        //         <Route path={routes.detailJobs} element={<Jobs/>}/>
        //
        //         <Route path={routes.mentors} element={<Mentors/>}/>
        //         <Route path={`${routes.mentors}/:id`} element={<MentorDetail/>}/>
        //
        //         <Route path={routes.events} element={<Events/>}/>
        //         <Route path={routes.internships} element={<Internships/>}/>
        //         <Route path={routes.it} element={<InIt/>}>
        //             <Route path={routes.courses} element={<Courses/>}/>
        //             <Route path={routes.detailCurses} element={<DetailCourses/>}/>
        //             <Route path={routes.event} element={<EventsList/>}/>
        //             <Route path={routes.internship} element={<InternshipsList/>}/>
        //             <Route path={routes.job} element={<VacanciesList/>}/>
        //             <Route path={routes.enterITArticles} element={<Articles/>}/>
        //         </Route>
        //         <Route path={routes.documents} element={<Documents/>}/>
        //         <Route path={routes.termsOfUse} element={<TermsOfUse/>}/>
        //         <Route path={routes.publicOffer} element={<PublicOffer/>}/>
        //         <Route path={routes.privacyPolicy} element={<PrivacyPolicy/>}/>
        //
        //         <Route path={'*'} element={<TemporaryPage/>}/>
        //         <Route path={routes.telegram} element={<Telegram/>}/>
        //         <Route path={routes.placeholder} element={<Placeholder/>}/>
        //
        //     </Routes>
        // </div>

    );
}

export default App;
