import {AuthorizationForm} from "./AuthorizationForm/AuthorizationForm";
import {RegAuthLayout} from "../RegAuthLayout";
import {useSelector} from "react-redux";
import {selectIsLoggedIn} from "../../../store/reducers/LoginReducer";
import {Navigate} from "react-router-dom";
import {routes} from "../../../routes/routes";

export default function Authorization() {
    const isLoggedIn = useSelector(selectIsLoggedIn);

    if (isLoggedIn) {
        return <Navigate to={routes.home}/>
    }
    return (
        <RegAuthLayout>
            <AuthorizationForm/>
        </RegAuthLayout>
    );
};
