import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../../services/AuthService";
import { setUserData } from "./UserReducer";
import {isLoggedIn} from "./CheckAuthReducer";

export const login = createAsyncThunk(
    "login/login",
    async (formVal, thunkAPI) => {
        const { rejectWithValue, dispatch } = thunkAPI;
        try {
            const res = await AuthService.login(formVal.email, formVal.password);
            localStorage.setItem('token', res.data.access);
            dispatch(setUserData(res.data));
            dispatch(isLoggedIn(true))
        } catch (err) {
            return rejectWithValue({ errorMessage: err.response.data.error });
        }
    }
);
export const logout = createAsyncThunk("login/logout", async (_, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
        const res = await AuthService.logout();
        localStorage.removeItem('token');
        dispatch(setUserData({}));
        return res.data.info;
    } catch (err) {
        return rejectWithValue(err.response.data.error);
    }
});

export const initialState = {
    isLoggedIn: false,
    isFormPending: false,
    loginError: ""
};

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(login.fulfilled, (state, action) => {
            state.isLoggedIn = true;
            state.isFormPending = false;
            state.loginError = "";
        });
        builder.addCase(login.rejected, (state, action) => {
            state.isLoggedIn = false;
            state.isFormPending = false;
            state.loginError = action.payload?.errorMessage;
        });
        builder.addCase(login.pending, (state) => {
            state.isFormPending = true;
        });
        builder.addCase(logout.fulfilled, (state) => {
            state.isLoggedIn = false;
        });
        builder.addCase(logout.rejected, (state) => {
            state.isFormPending = false;
        });
      
    }
});

export const selectIsLoggedIn = (state) => state.login.isLoggedIn

export const loginReducer = loginSlice.reducer;


export const {} = loginSlice.actions;


