import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {API_URL} from "../../http/AuthAPI";
import axios from 'axios';

export const checkAuth = createAsyncThunk(
    "checkAuth/checkAuth",
    async (_, thunkAPI) => {
        const { rejectWithValue, dispatch } = thunkAPI;
        // const token = localStorage.getItem('token');
        try {
            const res = await axios.get(`${API_URL}/token/refresh`, {withCredentials: true});
            console.log(res)
            localStorage.setItem('token', res.data.access);
            // console.log(res)
            // const res = await axios.get(`${API_URL}/token/refresh`, {withCredentials: true, headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}})
            // localStorage.setItem('token', res.data.accessToken);
            // dispatch(setUserData(res.data));
        } catch (err) {
            return rejectWithValue({ errorMessage: err.response.data.error });
        }
    }
);

export const initialState = {
    isLoggedIn: false,
    isFormPending: false,
    loginError: ""
};

const checkAuthSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        isLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase( checkAuth.fulfilled, (state, action) => {
            state.isLoggedIn = true;
            state.isFormPending = false;
            state.loginError = "";
        });
        builder.addCase( checkAuth.rejected, (state, action) => {
            state.isLoggedIn = false;
            state.isFormPending = false;
            state.loginError = action.payload?.errorMessage;
        });
        builder.addCase( checkAuth.pending, (state) => {
            state.isFormPending = true;
        });
       
    }
});

export const checkAuthSliceReducer = checkAuthSlice.reducer;


export const {isLoggedIn} = checkAuthSlice.actions;


