import React from "react";
import styles from './SocialLoginOptions.module.css'
import mailru from "../../../assets/mailru.png";
import mail from "../../../assets/mail.png";
import google from "../../../assets/google.png";

export const SocialLoginOptions = () => {
  return (
    <div className={styles.wrapper}>
     <div className={styles.headerBlock}>
       <span className={styles.line}></span>
       <span className={styles.title}>или войти через</span>
       <span className={styles.line}></span>
     </div>
      <div className={styles.imagesBlock}>
        <a href="https://mail.ru/" title={'Mail.ru'}>
          <img src={mailru} alt="Mail.ru"/>
        </a>
        <a href="https://mail.ru/" title={'Mail'}>
          <img src={mail} alt="Mail.ru"/>
        </a>
        <a href="https://mail.ru/" title={'GMail'}>
          <img src={google} alt="Gmail"/>
        </a>
      </div>
    </div>
  );
};
