import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import AuthService from "../../services/AuthService";

export const initialState = {
    isRegistered: false,
    isPending: false,
    registrationError: "",
    emailConfirmed: false,
};

export const registration = createAsyncThunk
("registration/register", async (formValue, thunkAPI) => {
        const {rejectWithValue} = thunkAPI;
        try {
            const res = await AuthService.registration(
                formValue.email,
                formValue.password, formValue.password
            );
            return res;
        } catch (err) {
            console.error('error', err)
            return rejectWithValue({errorMessage: err.response.data.error});
        }
    }
);
export const activation = createAsyncThunk
("registration/activate", async (data, thunkAPI) => {
        const {rejectWithValue} = thunkAPI;
        try {
            const res = await AuthService.activationEmail(data.linkEmail, data.key);
            console.log(res);
            return res;
        } catch (err) {
            console.error('error', err)
            return rejectWithValue({errorMessage: err.response.data.detail});
        }
    }
);
const registrationSlice = createSlice({
    name: "registration",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(registration.fulfilled, (state) => {
            state.isRegistred = true;
            state.isPending = false;
            state.registrationError = "";
            state.emailConfirmed = false;
        });
        builder.addCase(registration.rejected, (state, action) => {
            state.isRegistred = false;
            state.isPending = false;
            state.registrationError = action.payload?.errorMessage;
            state.emailConfirmed = false;
        });
        builder.addCase(registration.pending, (state) => {
            state.isPending = true;
        });
        builder.addCase(activation.fulfilled, (state) => {
            state.emailConfirmed = true;
        });
        builder.addCase(activation.rejected, (state, action) => {
            state.registrationError = action.payload
        })
    },
});
export const selectEmailConfirmed = (state) => state.registration.emailConfirmed
export const selectErrorRegistration = (state) => state.registration.registrationError
export const registrationReducer = registrationSlice.reducer;